<template>
  <el-card class="know-list">
    <div slot="header" class="clearfix" style="display: flex;">
      <el-image :src="require('@/assets/images/problemList/标签@2x.png')" style="width: 24px; height: 24px"></el-image>
      <span style="color: #00957e">测试用例</span>
    </div>

    <div class="operate" style="margin-bottom: 15px">
     <el-row>
       <el-col :span="6">
         <el-button-group>
           <el-button type="danger" size="small" @click="delData(selection)">删除</el-button>
         </el-button-group>
       </el-col>
       <el-col :span="18" style="text-align: right;">
         <el-button-group>
           <el-upload
               ref="upload"
               :action="''"
               :auto-upload="false"
               :http-request="handleUpload"
               :on-change="handleUploadChange"
               multiple
               style="display: none;" >
           </el-upload>
           <el-button type="primary" size="small" @click="handleCommand('choose')">选择多文件</el-button>
           <el-button type="success" size="small" @click="handleCommand('upload')" :disabled="!(fileLoading === false && uploadCount > 0)">开始上传</el-button>
           <el-button type="danger" :disabled="!$isPowers('questionEdit')" size="small" @click="$refs.dialog.visibled((dialogData = {}) ? true: true)">创建文件</el-button>
         </el-button-group>
       </el-col>
     </el-row>
    </div>
    <com-table
        ref="table"
        :data="tableData"
        :columns="columns"
        :page-size="50"
        @selection-change="selection = $event.map(d=>d.fullName);"
    >
      <template slot="action" slot-scope="scope">
       <template v-if="!scope.row.uid">
         <el-button @click="editData(scope.row)" type="text" size="small" v-if="['.out','.in','.txt','.log'].indexOf(scope.row.ext) > -1">编辑</el-button>
         <el-button @click="delData(scope.row.fullName)" type="text" size="small">删除</el-button>
         <el-button @click="downData(scope.row.fullName,scope.row.name)" type="text" size="small">下载</el-button>
         <el-button @click="unzipData(scope.row.fullName)" type="text" size="small" v-if="scope.row.type === 'zip'">解压</el-button>
       </template>
        <div style="display: flex;" v-else>
          <div style="width: 180px;flex:1;    border: 1px solid #00957e; height: 8px; border-radius: 4px; margin-top: 11px;margin-right: 5px;">
            <div :class="'huakuai-'+scope.row.uid" style="background-color: #00957e;width:0;height: 100%;"></div>
          </div>
          <el-button @click="handleCommand('del' , {...scope.row,rowIndex:scope.rowIndex})" type="text" size="small">删除</el-button>
        </div>
      </template>
    </com-table>
    <diy-dialog destroy-on-close ref="dialog" :title="dialogData.fullName ? '编辑文件【' + dialogData.fullName.substr(2)+'】':'创建文件'" width="450px" center>
      <form-create ref="dialogForm" :label-width="'100px'" v-model="dialogData" :components="[
              {name:'form-item',props:{prop:'name',label:'文件名称',required:true}},
              {name:'form-item',props:{prop:'content',label:'文件内容',required:true,type:'textarea',rows:8}},
          ]">
      </form-create>
      <div slot="footer" style="display: flex;justify-content: space-between">
        <el-button @click="$refs.dialog.visibled(false)">取 消</el-button>
        <el-button type="primary" @click="handleCommand('save' , {name:dialogData.name,content:dialogData.content,fullName:dialogData.fullName})">确 定</el-button>
      </div>
    </diy-dialog>
  </el-card>
</template>

<script>
export default {
  name: "use_cases",
  data(){
    return {
      columns:[
        {columnKey:'fullName',type:'selection'},
        {prop:'name',label:'文件名',minWidth:'220px', align:'center'},
        {prop:'sizeStr',label:'大小',width:'110' , align:'center'},
        {prop:'time',label:'操作时间',width:'160' , align:'center'},
        {label:'操作',width:'240' , slot:'action' , align:'center'},
      ],
      tableData: {
        url:'/xapi/question.question/use_cases'
      },
      selection:[],
      dialogData:{},
      fileLoading:false,
      uploadCount:0,
    }
  },
  methods:{
    handleCommand(command , data){
      if(command === 'save'){
        this.$http.post('/xapi/question.question/save_use_cases' , {...this.$route.params , ...(data || {})}).then(()=>{
          this.$handleMessage("保存成功！" , 'success',()=>{
            this.$refs.dialog.visibled(false);
            this.$refs.table.reload();
          });
        })
      }
      if(command === 'choose' && this.$refs.upload){
        this.$jquery(this.$refs.upload.$el).find('[name=file]').trigger('click');
      }
      if(command === 'upload' && this.$refs.upload && this.fileLoading === false){
        if(this.$refs.table.parseData.filter(d=>d.uid).length === 0){
          return this.$message.error("请选择要上传的文件");
        }
        this.fileLoading = true;
        for (let i = 0; i < this.$refs.table.parseData.length; i++) {
          if(this.$refs.table.parseData[i].uid){
            this.$refs.table.parseData[i].loading = true;
            this.handleUpload(this.$refs.table.parseData[i].raw , i)
          }
        }
      }
      if(command === 'del'){
        if(data.progress === 0){
          this.$refs.table.parseData.splice(data.rowIndex , 1);
          this.$refs.table.totalData -= 1;
          this.uploadCount = this.$refs.table.parseData.filter(d=>d.uid).length;
        }else{
          this.delData('./'+data.name)
        }
      }
    },
    handleUploadChange(file){
      if(this.$refs.table){
        if(this.$refs.table.parseData.filter(d=>d.name === file.name).length === 0){
          this.$refs.table.parseData.unshift({name:file.name , sizeStr:file.size,time:'',uid:file.uid,progress:0,raw:file.raw});
          this.$refs.table.totalData += 1;
          this.uploadCount ++;
        }
      }
    },
    handleUpload(file , index){
      let data = {...this.$route.params};
      let _this = this;
      let formdata = new FormData();
      formdata.append('file', file);
      for (const dk in data) {
        formdata.append(dk, data[dk]);
      }
      this.$http({
        url : '/xapi/question.question/upload_use_cases',
        data : formdata,
        method:'post',
        withCredentials:false,
        onUploadProgress:function(event){
          _this.$jquery(_this.$el).find('.huakuai-'+file.uid).css('width',(event.loaded/event.total*100)+'%');
        }
      }).then(function({data}){
        if((data || {}).name){
          var parseData = [..._this.$refs.table.parseData];
          parseData[index] = data;
          _this.$refs.table.parseData = [...parseData];
        }
      }).catch(error=>{
        _this.$jquery(_this.$el).find('.huakuai-'+file.uid).css('width','0');
        var parseData = [..._this.$refs.table.parseData];
        parseData[index].loading = false;
        _this.$refs.table.parseData = [...parseData];
      }).finally(()=>{
        if(_this.$refs.table.parseData.filter(d=>d.uid).length === 0){
          _this.$refs.upload.clearFiles();
        }
        _this.fileLoading = _this.$refs.table.parseData.filter(d=>d.loading === true).length > 0
        _this.uploadCount = this.$refs.table.parseData.filter(d=>d.uid).length + 1;
      })
    },
    editData(row){
      this.$http.post('/xapi/question.question/info_use_cases' , {...this.$route.params , fullName:row.fullName}).then((data)=>{
        this.dialogData = {...row , ...(data.data || {})}
        this.$refs.dialog.visibled(true);
      })
    },
    downData(ids,filename = true){
      var val = ids ;
      if(!(ids instanceof Array)){
        val = ids ? [ids] : [];
      }
      if(val.length === 0){
        return this.$message.error("请选择要下载的测试用例");
      }
      this.$tool.fileResponse('/xapi/question.question/down_use_cases' , {...this.$route.params , fullNames:val} , true)
    },
    unzipData(filename){
      this.$handleConfirm("确定解压该文件？" , '/xapi/question.question/unzip_use_cases' , {...this.$route.params , fullName:filename} , ()=>{
        this.$refs.table.reset();
      },{successMessage:"解压成功！"});
    },
    delData(ids){
      var val = ids , msg = "确定删除该测试用例？";
      if(ids instanceof Array){
        msg = "确定删除选中的测试用例？";
      }else{
        val = ids ? [ids] : [];
      }
      if(val.length === 0){
        this.$message.error("请选择要删除的测试用例");
      }else{
        this.$handleConfirm(msg , '/xapi/question.question/del_use_cases' , {...this.$route.params , fullNames:val} , ()=>{
          this.$refs.table.reset();
        },{successMessage:"删除成功！"});
      }
    },
  }
}
</script>

<style scoped>

</style>